/**
 * @license
 * @monterosa-sd/core
 *
 * Copyright © 2022 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

/* eslint-disable no-console */

/**
 * Represents the possible log levels.
 */
export enum LogLevel {
  /**
   * Verbose level. Provides detailed and extensive logging.
   */
  Verbose,
  /**
   * Debug level. Used for debugging and development purposes.
   */
  Debug,
  /**
   * Info level. Used to provide general information about the system's state.
   */
  Info,
  /**
   * Warn level. Indicates potential issues or situations that might cause problems in the future.
   */
  Warn,
  /**
   * Error level. Indicates critical errors and issues that require immediate attention.
   */
  Error,
  /**
   * Silent level. No logs will be output.
   */
  Silent,
}

/** @internal */
export type LogHandler = (
  logger: Logger,
  logType: LogLevel,
  ...args: unknown[]
) => void;

const Methods = {
  [LogLevel.Verbose]: 'log',
  // `console.debug` is not shown in Chrome and Edge by default. It must be
  // enabled manually. To avoid hassle for developers with settings debug
  // messages are sent to `console.log`
  [LogLevel.Debug]: 'log',
  [LogLevel.Info]: 'info',
  [LogLevel.Warn]: 'warn',
  [LogLevel.Error]: 'error',
} as const;

const logHandler: LogHandler = (logger, logLevel, ...args) => {
  if (logger.logLevel > logLevel) {
    return;
  }

  const method = Methods[logLevel as keyof typeof Methods];

  console[method](
    new Date().toISOString().replace(/[TZ]/gi, ' ').trim(),
    `${LogLevel[logLevel]}/${logger.name}:`,
    ...args,
  );
};

/** @internal */
export class Logger {
  private _logLevel: LogLevel = LogLevel.Warn;
  private _logHandler: LogHandler = logHandler;

  constructor(public name: string) {}

  get logLevel(): LogLevel {
    return this._logLevel;
  }

  set logLevel(level: LogLevel) {
    this._logLevel = level;
  }

  get logHandler(): LogHandler {
    return this._logHandler;
  }

  set logHandler(handler: LogHandler) {
    this.logHandler = handler;
  }

  log(...args: unknown[]) {
    this.logHandler(this, LogLevel.Verbose, ...args);
  }

  debug(...args: unknown[]) {
    this.logHandler(this, LogLevel.Debug, ...args);
  }

  info(...args: unknown[]) {
    this.logHandler(this, LogLevel.Info, ...args);
  }

  warn(...args: unknown[]) {
    this.logHandler(this, LogLevel.Warn, ...args);
  }

  error(...args: unknown[]) {
    this.logHandler(this, LogLevel.Error, ...args);
  }
}
