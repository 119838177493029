/**
 * @license
 * @monterosa-sd/core
 *
 * Copyright © 2021-2024 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { MonterosaSdk, MonterosaKit, Kits } from './types';

/**
 * @internal
 */
export class Sdk implements MonterosaSdk {
  private _isDestroyed: boolean = false;
  private _kits: Kits;

  constructor(private readonly _host: string, private readonly _id: string) {
    this._kits = new Map();
  }

  get host(): string {
    this.checkDestroyed();
    return this._host;
  }

  get id(): string {
    this.checkDestroyed();
    return this._id;
  }

  get kits(): Kits {
    return this._kits;
  }

  set isDestroyed(value: boolean) {
    this._isDestroyed = value;
  }

  public destroy() {
    this.deleteKits();
    this._isDestroyed = true;
  }

  private deleteKits() {
    this.kits.forEach((kit, name) => {
      this.kits.delete(name);
      kit.delete();
    });
  }

  private checkDestroyed(): void {
    if (this._isDestroyed) {
      throw new Error(`SDK id ${this.id} destroyed`);
    }
  }
}

/**
 * @internal
 */
export function addKit(sdk: MonterosaSdk, name: string, kit: MonterosaKit) {
  sdk.kits.set(name, kit);
}

/**
 * @internal
 */
export function getKit(
  sdk: MonterosaSdk,
  name: string,
): MonterosaKit | undefined {
  return sdk.kits.get(name);
}

/**
 * @internal
 */
export function deleteKit(sdk: MonterosaSdk, name: string): void {
  sdk.kits.delete(name);
}
