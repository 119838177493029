/**
 * @license
 * @monterosa-sd/core
 *
 * Copyright © 2023-2024 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { version } from '../package.json';

/**
 * The current SDK version.
 *
 * @public
 */
export const VERSION = version;

/**
 * Device ID storage key name
 *
 * @internal
 */
export const DEVICE_ID_KEY = 'device_id';
