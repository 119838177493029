/**
 * @license
 * @monterosa-sd/core
 *
 * Copyright © 2024 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { MonterosaSdk, Project } from './types';

export class ProjectImpl implements Project {
  constructor(public sdk: MonterosaSdk, public id: string) {}
  private _isDestroyed: boolean = false;

  destroy(): void {
    this._isDestroyed = true;
  }
}
