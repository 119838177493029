/**
 * @license
 * @monterosa-sd/core
 *
 * Copyright © 2022-2024 Monterosa. All rights reserved.
 *
 * More details on the license can be found at https://www.monterosa.co/sdk/license
 */

import { v4 as uuidv4 } from 'uuid';
import {
  getItem as getStorageItem,
  setItem as setStorageItem,
  checkAvailability as checkStorageAvailability,
} from '@monterosa-sdk/util';

import { MonterosaSdk, Project } from './types';
import { DEVICE_ID_KEY } from './constants';
import { Sdk } from './sdk';
import { ProjectImpl } from './project';

const sdks = new Map<string, MonterosaSdk>();
const projects = new Map<string, Project>();

let DEVICE_ID: string;

/**
 * An array of all configured sdks.
 *
 * @public
 */
export function getSdks(): MonterosaSdk[] {
  return Array.from(sdks.values());
}

/**
 * Retrieves an SDK instance by its name.
 *
 * @example
 * ```javascript
 * const sdk = getSdk('my-sdk');
 * ```
 *
 * @param id - Id of the sdk.
 *
 * @public
 */
export function getSdk(id: string): MonterosaSdk {
  const sdk = sdks.get(id);

  if (!sdk) {
    throw new Error(
      `No Monterosa Sdk with id "${id}" has been configured.
        You need to call configure() first`,
    );
  }

  return sdk;
}

/**
 * @internal
 */
export function getDeviceId() {
  if (DEVICE_ID !== undefined) {
    return DEVICE_ID;
  }

  if (!checkStorageAvailability()) {
    DEVICE_ID = uuidv4();

    return DEVICE_ID;
  }

  const value = getStorageItem(DEVICE_ID_KEY);

  if (value === null) {
    DEVICE_ID = uuidv4();

    setStorageItem(DEVICE_ID_KEY, DEVICE_ID);
  } else {
    DEVICE_ID = value;
  }

  return DEVICE_ID;
}

/**
 * Retrieves or creates a MonterosaSdk instance for the specified host and id.
 *
 * @remarks
 * - If an existing instance with the same id is found, it is returned.
 *
 * - If no existing instance is found, a new instance is created and returned.
 *
 * - If there are existing instances with different hosts, an error is thrown.
 *
 * @example
 *
 * Configure default sdk with host and project id
 *
 * ```javascript
 * const sdk = getSpace({
 *   host: "...",
 *   id: "..."
 * });
 * ```
 *
 * @param host - Studio host
 * @param id - The unique ID of the space
 *
 * @throws Error if there are existing instances with different hosts.
 *
 * @returns SDK instance.
 *
 * @public
 */
export function getSpace(host: string, id: string): MonterosaSdk {
  const existingSdk = sdks.get(id);

  if (existingSdk !== undefined) {
    return existingSdk;
  }

  /**
   * As the legacy Enmasse library is currently in use, it only allows a single host.
   * Therefore, we need to ensure that the host of the new SDK being added matches
   * the host of the first SDK in the collection.
   */
  if (sdks.size > 0) {
    const [firstItem] = Array.from(sdks.values());

    if (firstItem.host !== host) {
      throw new Error(
        'All SDKs must belong to the same host. The expected host is ' +
          `"${firstItem.host}", but a different host "${host}" was provided ` +
          'for the new SDK.',
      );
    }
  }

  const sdk = new Sdk(host, id);

  sdks.set(id, sdk);

  return sdk;
}

/**
 * Retrieves a project from the SDK.
 *
 * @remarks
 * - If the project with the specified ID already exists,
 *   it returns the existing project.
 *
 * - Otherwise, it creates a new project and returns it.
 *
 * @param sdk - The Monterosa SDK instance.
 * @param id - The ID of the project to retrieve.
 *
 * @returns The project with the specified ID.
 */
export function getProject(sdk: MonterosaSdk, id: string): Project {
  const existingProject = projects.get(id);

  if (existingProject !== undefined) {
    return existingProject;
  }

  const project = new ProjectImpl(sdk, id);

  projects.set(id, project);

  return project;
}

/**
 * Destroy initialised sdk and associated projects
 *
 * @example
 * ```javascript
 * const sdk = configure(...);
 *
 * destroy(sdk);
 * ```
 *
 * @param sdk - sdk instance
 */
export function destroy(sdk: MonterosaSdk) {
  const { id } = sdk;

  sdk.destroy();
  sdks.delete(id);

  projects.forEach((project, projectId) => {
    if (project.sdk === sdk) {
      project.destroy();
      projects.delete(projectId);
    }
  });
}
